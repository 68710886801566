import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Chantiers from '../views/Chantiers.vue'
import Chantier from '../views/Chantier.vue'
import ChantierLigne from '../views/ChantierLigne.vue'
import Journees from '../views/Journees.vue'
import Journee from '../views/Journee.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'home',
    component: Home
  },
  {
    path: '/',
    alias: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/chantiers',
    name: 'chantiers',
    component: Chantiers
  },
  {
    path: '/chantiers/:id',
    name: 'chantier',
    component: Chantier
  },
  {
    path: '/chantier_lignes/:id',
    name: 'chantierLigne',
    component: ChantierLigne
  },
  {
    path: '/journees',
    name: 'journees',
    component: Journees
  },
  {
    path: '/journees/:id',
    name: 'journee',
    component: Journee
  },
]

const router = new VueRouter({
  routes,
  mode: 'history',
  base: process.env.BASE_URL
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/', '/login'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});

export default router