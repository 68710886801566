<template>
  <div id="clotureForm">
        <div class="card mb-3">
          <div class="card-body">

            <!-- form -->
            <div class="mt-1">
              <h6 class="mb-3 text-center">Demande de confirmation</h6>

              <div class="mt-3"></div>

              <FormulateForm @submit="validationForm">
            
                <div class="form-check text-left">
                  <input v-model="chantier.isDone" class="form-check-input" id="checkboxIsDone" type="checkbox" value="">
                  <label class="form-check-label" for="checkboxIsDone">Je confirme que le chantier est terminé</label>
                </div>

                <div v-if="error" class="alert alert-warning w-100">
                  <i class="fas fa-exclamation-triangle"></i>
                  {{error}}
                </div>
                
                <div class="mt-3"></div>

                <FormulateInput
                  type="submit"
                  label="Valider"
                  id="buttonClotureChantier"
                  input-class="btn btn-primary w-100 text-white mt-3"
                />
                
              </FormulateForm>

            </div>

          </div>
        </div>

  </div>
</template>

<script>
//import router from '../router'
import UserService from '../services/user.service'

var temp = Object.freeze({
  isDone: false, 
});

export default {
  name: 'Form',
  components: {
  },
  data () {
    return {
      userData: [],
      error: null,
      chantier: Object.assign({}, temp),
    }
  },
  async created() {
    this.userData = JSON.parse(localStorage.getItem('userData'));
    await UserService.getChantier(this.$route.params.id).then((response) => {
        this.chantier = response.data;  
      },
      error => {
        this.content = (error.response && error.response.data) || error.message || error.toString();
      }
    );
  },
  watch: {
    type(val) {
      if (val == 'add') {
        this.chantier = Object.assign({}, temp);
      }
    }
  },
  mounted() {
  },
  methods: {
    async reload() {
       await UserService.getUserMe().then(
        response => {
          localStorage.setItem('userData', response.data);
        },
        error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
        }
      );
      this.$router.go();
    },
    async validationForm() {
      if(this.chantier.isDone == true) {
        const chantier = {
          isDone: true, 
        };
        await UserService.patchChantier(this.$route.params.id, chantier).then(
          () => {
              UserService.getUserMe().then(
                response => {
                  localStorage.setItem('userData', response.data);
                },
                error => {
                  this.content = (error.response && error.response.data) || error.message || error.toString();
                }
              );
          },
        );
        document.getElementById('buttonClotureChantier').click();
        document.getElementById('closeModalDoneChantier').click();
        this.reload();
      }
    },  
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

