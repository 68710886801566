<template>
  <div>

    <!-- Footer Nav -->
    <div class="footer-nav-area" id="footerNav">
      <div class="container px-0">
        <!-- Footer Content -->
        <div class="footer-nav position-relative">
          <ul class="h-100 d-flex align-items-center justify-content-between ps-0">
            <li>
                <a href="/home"><i class="fas fa-home"></i><span>Accueil</span></a>
            </li>
            <li>
                <a href="/chantiers"><i class="fas fa-truck"></i><span>Chantiers</span></a>
            </li>
            <li>
                <a href="/journees"><i class="fas fa-calendar" aria-hidden="true"></i><span>Semaine</span></a>
            </li>
          </ul>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data: function() {
    return {
      isMember: true,
      loggedIn: this.$store.state.auth.status.loggedIn,
    }
  },
  created() {
    if(localStorage.getItem('userData')) {
      this.userData = JSON.parse(localStorage.getItem('userData'));
    }
  }
}
</script>
