import axios from 'axios';

//const urlApi = "https://127.0.0.1:8000/api/";
const urlApi = "https://oktime.fr/api/";

class AuthService {
  login(user) {
    return axios
      .post(urlApi + "login", {
        username: user.username,
        password: user.password
      })
      .then(response => {
        if (response.data.token) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }
        return response.data;
      }
    );
  }

  logout() {
    localStorage.removeItem('user');
    localStorage.removeItem('userData');
  }
}

export default new AuthService();
