//import axios from 'axios';
import api from './api.js'
//import authHeader from './auth-header';

//const urlApi = "https://127.0.0.1:8000/api/";
const urlApi = "https://oktime.fr/api/";

class UserService {
getLastVersion() {
    return api.get(urlApi + 'lastPwa');
}

getUserMe() {
    return api.get(urlApi + 'me/id');
}

//Chantier
getChantiers() {
    return api.get(urlApi + 'chantiers');
}

getChantiersForm() {
    return api.get(urlApi + 'chantiers?q=form');
}

getChantier(id) {
    return api.get(urlApi + 'chantiers/' + id);
}

patchChantier(id, data) {
    return api.patch(urlApi + 'chantiers/' + id, data);
}

postChantier(data) {
    return api.post(urlApi + 'chantiers', data);
}

//ChantierLigne
getChantierLignes(filters) {
    return api.get(urlApi + 'chantier_lignes' + filters);
}

getChantierLignesForm(filters) {
    return api.get(urlApi + 'chantier_lignes' + filters);
}

getChantierLigne(id) {
    return api.get(urlApi + 'chantier_lignes/' + id);
}

patchChantierLigne(id, data) {
    return api.patch(urlApi + 'chantier_lignes/' + id, data);
}

postChantierLigne(data) {
    return api.post(urlApi + 'chantier_lignes', data);
}

//Journee
getJournees(filters) {
    return api.get(urlApi + 'journees' + filters);
}

getJournee(id) {
    return api.get(urlApi + 'journees/' + id);
}

patchJournee(id, data) {
    return api.patch(urlApi + 'journees/' + id, data);
}

postJournee(data) {
    return api.post(urlApi + 'journees', data);
}

// Client
getClientsForm() {
    return api.get(urlApi + 'clients?q=form');
}

// Contact
getContactsForm() {
    return api.get(urlApi + 'contacts?q=form');
}

//Operation
getOperations() {
    return api.get(urlApi + 'operations');
}

getOperation(id) {
    return api.get(urlApi + 'operations/' + id);
}

patchOperation(id, data) {
    return api.patch(urlApi + 'operations/' + id, data);
}

postOperation(data) {
    return api.post(urlApi + 'operations', data);
}

}

export default new UserService();
