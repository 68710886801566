<template>
  <div>

    <Header />

    <div class="page-content-wrapper">

      <div class="container mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 py-2 px-3 rounded" style="margin-top:70px;">
            <li class="breadcrumb-item"><a href="/home"><i class="fas fa-chevron-left ml-2 mr-2"></i><span class="ml-2">&nbsp; &nbsp;</span>Accueil</a></li>
          </ol>
        </nav>
      </div>

      <div class="container mb-3">
        <div class="card  rounded-0 rounded-top bg-primary bg-img" style="background-image: url('img/core-img/1.png')">
          <div class="card-body text-center py-3">
            <h6 class="mb-0 text-white line-height-1">Récap semaine {{weekNumber}}</h6>
          </div>
        </div>

        
        <ul class="ps-0 chat-user-list rounded-0">
          <li v-for="(journee, index) in data" :key="journee.id" class="rounded-0 px-3 pt-2 pb-1 chat-unread text-left" v-bind:class="{ 'rounded-bottom': index === (data.length-1), 'bg-light-warning': journee.class === 'warning', 'bg-light-success': journee.class === 'success' }">
            <a v-if="journee.value.id" class="d-flex" v-bind:href="'/journees/' + journee.value.id">
              <div class="chat-user-info" style="width:100%;">
                <div class="row">
                  <div class="col-6">
                    <h6 class="mt-2 mb-1">{{journee.name|capitalize}} {{journee.value.date|formatDate}}</h6>
                  </div>
                  <div class="col-6 text-right" style="align-content:left;">
                      <span class="small" style="margin-right:10px;">{{ journee.value.heures }} H</span>
                      <span class="badge bg-success" style="margin-right:10px;"><i class="bi bi-check-circle"></i></span>
                      <span class="badge rounded-pill" v-bind:class="journee.value.repas == 'panier' ? 'bg-info' : 'bg-secondary'">{{journee.value.repas == 'panier' ? 'P' : 'R'}}</span>
                  </div>
                </div>
              </div>
            </a>
            <div v-else class="chat-user-info" style="width:100%;">
              <div class="row">
                <div class="col-8" style="padding-right:0;">
                  <h6 v-if="journee.date" class="mt-2 mb-1">{{journee.name|capitalize}} {{journee.date}}</h6>
                  <h6 v-else class="mt-2 mb-1">{{journee.name|capitalize}}</h6>
                </div>
                <div v-if="journee.date" class="col-4 text-right" style="align-content:left; padding-left:0;padding-right:15px;">
                  <a href="#" class="" data-bs-toggle="modal" data-bs-target="#modalNewJournee">
                    <span class="badge bg-danger"><i class="bi bi-x-circle"></i></span>
                  </a>  
                </div>
                <div v-else class="col-4 text-right" style="align-content:left; padding-left:0;padding-right:15px;">
                    <span class="badge bg-danger"><i class="bi bi-x-circle"></i></span>
                </div>
              </div>
            </div>
            <div v-if="journee.value.id" class="dropstart chat-options-btn">
              <a v-bind:href="'/journees/' + journee.value.id" class="text-rbo">
                <i class="fas fa-chevron-right"></i>
              </a>
            </div>
          </li>
        </ul>
        
        <!-- modal > new clôture journee -->
        <div class="add-new-contact-modal modal fade px-0" id="modalNewJournee" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modalNewJournee" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-body">
                <div class="d-flex align-items-center justify-content-between">
                  <button class="btn btn-close p-1 ms-auto me-0" type="button" id="closeModalJournee" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <FormJournee :type="formType" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- infos global -->
      <div class="container mb-3">
        <!--
        <div class="card bg-info rounded-0 rounded-top">
          <div class="py-1 text-center">
            <h5 class="pt-2 text-white text-center">Client</h5>
          </div>
        </div>
        -->
        <div class="card rounded-0 rounded-bottom">
          <ul class="list-group">
            <li class="list-group-item"><b>Total heures : </b> {{totalHeures}} H</li>
            <li class="list-group-item"><b>Total panier : </b> {{totalPanier}}</li>
            <li class="list-group-item"><b>Total restaurant : </b> {{totalRestaurant}}</li>
          </ul>
        </div>
      </div>

    </div>
    <div class="pb-3"></div>

    <Footer />

  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import FormJournee from '@/components/JourneeForm.vue'
import UserService from '../services/user.service';

export default {
  name: 'Journees',
  data: function() {
    return {
      lsUserData: [],
      journees: [],
      loggedIn: this.$store.state.auth.status.loggedIn,
      data: [],
      weekNumber: 0,
      totalHeures: 0,
      totalPanier: 0,
      totalRestaurant: 0,
    }
  },
  components: {
    Header,
    Footer,
    FormJournee,
  },
  filters: {
    formatDate: function (value) {
      if (!value) return ''
      value = new Date(value).toLocaleDateString();
      return value;
    },
    formatDay: function (value) {
      if (!value) return ''
      var options = { weekday: 'long'};
      value = new Date(value).toLocaleDateString(undefined, options);
      return value;
    },
    capitalize: function (value) {
    if (!value) return ''
    value = value.toString()
    return value.charAt(0).toUpperCase() + value.slice(1)
  }
  }, 
  async created() {
    this.lsUserData = JSON.parse(localStorage.getItem('userData'));

    this.data = [
            {id: '1', name: 'lundi', value : [], date: null, class: ''},
            {id: '2', name: 'mardi', value : [], date: null, class: ''},
            {id: '3', name: 'mercredi', value : [], date: null, class: ''},
            {id: '4', name: 'jeudi', value : [], date: null, class: ''},
            {id: '5', name: 'vendredi', value : [], date: null, class: ''},
    ];

    var today = new Date();

    this.data.forEach(element => {
      if (element.id == today.getDay()) {
        element.date = today.toLocaleDateString();
        element.class = 'warning';
      }
    });

    await UserService.getJournees('?cId=' + this.lsUserData.collaborateur.id).then(
        response => {
          this.journees = response.data['hydra:member'];

            this.journees.forEach(journee => {
            var options = { weekday: 'long'};
            var day = new Date(journee.date).toLocaleDateString(undefined, options);

            this.data.forEach(element => {
              if (element.name == day) {
              element.value = journee;
              this.totalHeures = this.totalHeures + parseFloat(journee.heures);

              if (journee.repas == 'panier') {this.totalPanier = this.totalPanier + 1;}
              if (journee.repas == 'restaurant') {this.totalRestaurant = this.totalRestaurant + 1;}

              if (new Date(journee.date).getDay() == today.getDay()) {
              element.class = 'success';
              }
              }
            });
          });
        },
        error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
        }
      );
      
      // récupère le numéro de la semaine en cours
      var currentdate = new Date();
      var oneJan = new Date(currentdate.getFullYear(),0,1);
      var numberOfDays = Math.floor((currentdate - oneJan) / (24 * 60 * 60 * 1000));
      this.weekNumber = Math.ceil(numberOfDays / 7);
  },
  methods: {
  },
   watch: { 
   '$route.query.type': {
      handler: function(type) {
        this.formType = type;
      },
      deep: true,
      immediate: true
    }
  }
}
</script>