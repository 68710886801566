<template>
  <div id="operationForm">
        <div class="card mb-3">
          <div class="card-body">

            <!-- form -->
            <div class="mt-3">
              <h6 class="mb-3 text-center">{{ editing ? 'Modifier' : 'Enregistrer' }} un temps passé</h6>

              <FormulateForm @submit="validationForm">
            
                <div class="text-left mt-4 mb-1"><label>Chantier</label></div>
                <Multiselect
                  v-model="operation.chantier" 
                  :options="formSelectChantier"
                  :searchable="true" 
                  :close-on-select="true" 
                  :show-labels="false" 
                  :custom-label="formSelectChantier.label"
                  label="label"
                  track-by="value"
                  placeholder="Selectionner le chantier"
                  @select="searchChantierLigne($event)"
                  ></Multiselect>
                  
                <div class="mb-4"></div>

                <div class="text-left mt-4 mb-1"><label>Ligne</label></div>
                <Multiselect
                  v-model="operation.chantierLigne" 
                  :options="formSelectChantierLigne"
                  :searchable="true" 
                  :close-on-select="true" 
                  :show-labels="false" 
                  :custom-label="formSelectChantierLigne.label"
                  label="label"
                  track-by="value"
                  placeholder="Selectionner la ligne du chantier"
                  ></Multiselect>
                  
                <div class="mb-4"></div>

                <FormulateInput
                  type="datepicker"
                  language="fr"
                  name="date"
                  label="Date"
                  format="dd/MM/yyyy"
                  v-model="operation.debut"
                />

                <div v-if="isDuree == false" class="form-check text-left">
                  <input v-on:click="isDuree = true" class="form-check-input" id="checkboxDuree" type="checkbox" value="">
                  <label class="form-check-label" for="checkboxDuree">Saisir une durée</label>
                </div>

                <div v-if="isDuree" class="form-check text-left">
                  <input v-on:click="isDuree = false" class="form-check-input" id="checkboxPeriode" type="checkbox" value="">
                  <label class="form-check-label" for="checkboxPeriode">Saisir une période</label>
                </div>

                <div v-if="isDuree == false" class="text-left mt-4 mb-1"><label>Heure debut</label></div>
                <div class="row">
                  <div class="col-5">
                    <FormulateInput
                      v-if="isDuree == false"
                      type="number"
                      name="Heure debut"
                      :show-labels="false" 
                      placeholder="Heure"
                      min="0"
                      v-model="operation.hDebutH"
                    />
                  </div>
                  <div v-if="isDuree == false" class="col-2 mt-2">H</div>
                  <div class="col-5">
                    <FormulateInput
                      v-if="isDuree == false"
                      type="number"
                      name="Heure debut"
                      :show-labels="false" 
                      placeholder="Minutes"
                      min="0"
                      max="55"
                      :step="step"
                      v-model="operation.hDebutM"
                    />
                  </div>
                </div>

                <div v-if="isDuree == false" class="text-left mb-1"><label>Heure fin</label></div>
                <div class="row">
                  <div class="col-5">
                    <FormulateInput
                      v-if="isDuree == false"
                      type="number"
                      name="Heure fin"
                      :show-labels="false" 
                      placeholder="Heure"
                      min="0"
                      v-model="operation.hFinH"
                    />
                  </div>
                  <div v-if="isDuree == false" class="col-2 mt-2">H</div>
                  <div class="col-5">
                    <FormulateInput
                      v-if="isDuree == false"
                      type="number"
                      name="Heure fin"
                      :show-labels="false" 
                      placeholder="Minutes"
                      min="0"
                      max="55"
                      :step="step"
                      v-model="operation.hFinM"
                    />
                  </div>
                </div>

                <div v-if="isDuree" class="text-left mt-4 mb-1"><label>Temps passé (H)</label></div>
                <div class="row">
                  <div class="col-5">
                    <FormulateInput
                      v-if="isDuree"
                      type="number"
                      name="Temps passé (H)"
                      :show-labels="false" 
                      placeholder="Heure"
                      validation="number|between:0,10"
                      min="0"
                      max="10"
                      v-model="operation.dureeMinH"
                    />
                  </div>
                  <div v-if="isDuree" class="col-2 mt-2">H</div>
                  <div class="col-5">
                    <FormulateInput
                      v-if="isDuree"
                      type="number"
                      name="Temps passé (H)"
                      :show-labels="false" 
                      placeholder="Minutes"
                      min="0"
                      max="55"
                      :step="step"
                      v-model="operation.dureeMinM"
                    />
                  </div>
                </div>

                <div v-if="error" class="alert alert-warning w-100">
                  <i class="fas fa-exclamation-triangle"></i>
                  {{error}}
                </div>
                
                <FormulateInput
                  type="submit"
                  label="Valider"
                  id="buttonOperation"
                  input-class="btn btn-primary w-100 text-white mt-3"
                />
                
              </FormulateForm>

            </div>

          </div>
        </div>

  </div>
</template>

<script>
//import router from '../router'
import UserService from '../services/user.service'

var temp = Object.freeze({
  chantier: '',
  chantierLigne: null,
  debut: '',
  hDebut: '',
  hFin: '',
  dureeMin: null,
  dureeMinH: null,
  dureeMinM: null,
});

export default {
  name: 'Form',
  components: {
  },
  props: {
    type: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      userData: [],
      formSelectChantier: [],
      formSelectChantierLigne: [],
      error: null,
      operation: Object.assign({}, temp),
      editing: false,
      isDuree: false,
      operationNewId: null,
      tomorrow: null,
      step: null,
    }
  },
  async created() {
    this.userData = JSON.parse(localStorage.getItem('userData'));

    if (this.userData.user.siteDureePas != null) {
      this.step = this.userData.user.siteDureePas;
    } else {
      this.step = 15;
    }

    await UserService.getChantiersForm().then(
    response => {
      this.formSelectChantier = response.data['hydra:member'];
    },
    error => {
      this.content = (error.response && error.response.data) || error.message || error.toString();
    }
    );

    this.tomorrow = {
      disabledDates: {
        from: new Date(),
      },
    }

  },
  watch: {
    type(val) {
      if (val == 'add') {
        this.operation = Object.assign({}, temp);
      }
    }
  },
  mounted() {
    if ("id" in this.$route.params) {
      this.editing = true;
    } else {
      this.operation.debut = new Date();
    }
  },
  methods: {
    searchChantierLigne(event) {
      // chantierLignes
      UserService.getChantierLignesForm('?q=form&cId=' + event.value).then((response) => {
        this.formSelectChantierLigne = response.data['hydra:member'];
      },
      error => {
        this.content = (error.response && error.response.data) || error.message || error.toString();
      }
    );
    },
    async reload() {
       await UserService.getUserMe().then(
        response => {
          localStorage.setItem('userData', response.data);
        },
        error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
        }
      );
      this.$router.go();
    },
    async validationForm() {
      let debut = null;
      if (this.operation.hDebutH != null) {
        debut = (new Date(this.operation.debut + ' ' + this.operation.hDebutH + ':' + this.operation.hDebutM + ':00 UTC')).toJSON();
      }

      let fin = null;
      if (this.operation.hFinH != null) {
        fin = (new Date(this.operation.debut + ' ' + this.operation.hFinH + ':' + this.operation.hFinM + ':00 UTC')).toJSON();
      }

      let dureeMin = null;
      if (this.operation.dureeMinH != null && this.isDuree == true) {
        let duration = null;
        duration = this.operation.dureeMinH * 60;
        duration = duration + parseInt(this.operation.dureeMinM);

        /* méthode convertion minutes => heures décimales
        let hours = null;
        let minutes = null;
        let hourDec = null;
        hours = Math.floor(duration / 60);
        console.log('h: ' + hours)
        minutes = duration % 60;
        console.log('m: ' + minutes)
        hourDec = minutes / 60;
        console.log('hDec: ' + hourDec)
        dureeMin = (hours + hourDec).toFixed(2);
        */
        dureeMin = duration;
        fin = (new Date()).toJSON();
        debut = (new Date()).toJSON();
      }

      const operation = {
        chantier: '/api/chantiers/' + this.operation.chantier.value,
        chantierLigne: this.operation.chantierLigne != null ? '/api/chantier_lignes/' + this.operation.chantierLigne.value : null,
        dureeMin: dureeMin,
        debut: debut,
        fin: fin,
      };

      await UserService.postOperation(operation).then(
        res => {
          let operationId = res.data['@id'];
          this.operationNewId = operationId.slice(16);
            UserService.getUserMe().then(
              response => {
                localStorage.setItem('userData', response.data);
              },
              error => {
                this.content = (error.response && error.response.data) || error.message || error.toString();
              }
            );
        },
      );
      document.getElementById('buttonOperation').click();
      document.getElementById('closeModalOperation').click();
      this.reload();
   
    },  
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

