<template>
  <div id="chantierForm">
        <div class="card mb-3">
          <div class="card-body">

            <!-- rem form -->
            <div class="mt-3">
              <h6 class="mb-3 text-center">{{ editing ? 'Modifier' : 'Enregistrer' }} un chantier</h6>

              <FormulateForm @submit="validationForm">
                <FormulateInput
                  type="text"
                  name="sample"
                  label="Nom"
                  placeholder="Saisisser le nom du chantier"
                  v-model="chantier.nom"
                />
            
                <div class="text-left mt-4 mb-1"><label>Client</label></div>
                <Multiselect
                  v-model="chantier.client" 
                  :options="formSelectClient"
                  :searchable="true" 
                  :close-on-select="true" 
                  :show-labels="false" 
                  :custom-label="formSelectClient.label"
                  label="label"
                  track-by="value"
                  placeholder="Selectionner le nom du client"
                  @select="searchContact($event)"
                  ></Multiselect>
                  
                <div class="mb-4"></div>

                <div class="text-left mt-4 mb-1"><label>Contact</label></div>
                <Multiselect
                  v-model="chantier.contact" 
                  :options="formSelectContact"
                  :searchable="true" 
                  :close-on-select="true" 
                  :show-labels="false" 
                  :custom-label="formSelectContact.label"
                  label="label"
                  track-by="value"
                  placeholder="Selectionner le nom du contact"
                ></Multiselect>

                <div class="mb-4"></div>

                <div class="text-left mt-4 mb-1"><label>Pilote</label></div>
                <Multiselect
                  v-model="chantier.pilote" 
                  :options="formSelectPilote"
                  :searchable="true" 
                  :close-on-select="true" 
                  :show-labels="false" 
                  :custom-label="formSelectPilote.label"
                  label="label"
                  track-by="value"
                  placeholder="Selectionner le nom du pilote"
                  :internal-search="false"
                ></Multiselect>

                <div class="mb-4"></div>

                <FormulateInput
                    type="datepicker"
                    language="fr"
                    name="date"
                    label="Date début"
                    format="dd/MM/yyyy"
                    v-model="chantier.dateDebutPrevu"
                />

                <FormulateInput
                    type="datepicker"
                    language="fr"
                    name="date"
                    label="Date fin"
                    format="dd/MM/yyyy"
                    v-model="chantier.dateFinPrevu"
                />

                <FormulateInput
                  type="text"
                  name="sample"
                  label="Durée"
                  placeholder="ex: 06h30, 06:30, 06-30, 06.30, 06H30"
                  v-model="chantier.dureeHReel"
                />

                <FormulateInput
                  type="text"
                  name="sample"
                  label="Adresse"
                  placeholder="Saisisser l'adresse du chantier"
                  v-model="chantier.adresse1"
                />

                <FormulateInput
                  type="text"
                  name="sample"
                  label="Complément d'adresse"
                  v-model="chantier.adresse2"
                />

                <FormulateInput
                  type="text"
                  name="sample"
                  label="Code postal"
                  v-model="chantier.cp"
                />

                <FormulateInput
                  type="text"
                  name="sample"
                  label="Ville"
                  v-model="chantier.ville"
                />

                <div v-if="error" class="alert alert-warning w-100">
                  <i class="fas fa-exclamation-triangle"></i>
                  {{error}}
                </div>
                
                <FormulateInput
                  type="submit"
                  label="Valider"
                  id="buttonChantier"
                  input-class="btn btn-primary w-100 text-white mt-3"
                />
                
              </FormulateForm>

            </div>

          </div>
        </div>

  </div>
</template>

<script>
import router from '../router'
import UserService from '../services/user.service'

var temp = Object.freeze({
  client: '',
  contact: '',
  pilote: '',
  code: '',
  nom: '',
  dureeHVendu: '',
  dureeHReel: '',
  dateDebutPrevu: '',
  dateFinPrevu:'',
  dateDebutReel:'',
  datefinReel:'',
  adresse1:'',
  adresse2:'',
  cp:'',
  ville:'',
});

export default {
  name: 'Form',
  components: {
  },
  props: {
    type: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      userData: [],
      formSelectClient: [],
      formSelectContact: [],
      formSelectPilote: [],
      error: null,
      chantier: Object.assign({}, temp),
      editing: false,
      chantierNewId: null,
      tomorrow: null,
    }
  },
  async created() {
    this.userData = JSON.parse(localStorage.getItem('userData'));

    await UserService.getClientsForm().then(
    response => {
      this.formSelectClient = response.data['hydra:member'];
    },
    error => {
      this.content = (error.response && error.response.data) || error.message || error.toString();
    }
    );

    await UserService.getContactsForm().then(
    response => {
      this.formSelectContact = response.data['hydra:member'];
    },
    error => {
      this.content = (error.response && error.response.data) || error.message || error.toString();
    }
    );
    
    this.formSelectPilote = [{ value: this.userData.user.id, label: this.userData.user.firstName + ' ' + this.userData.user.lastName}]

    this.tomorrow = {
      disabledDates: {
        from: new Date(),
      },
    }

  },
  watch: {
    type(val) {
      if (val == 'add') {
        this.chantier = Object.assign({}, temp);
      }
    }
  },
  mounted() {
    if ("id" in this.$route.params) {
      this.getChantierById();
      this.editing = true;
    } else {
      this.chantier.dateDebutPrevu = new Date();
      this.chantier.pilote = this.userData.user ? {value : this.userData.user.id, label: this.userData.user.firstName + ' ' + this.userData.user.lastName } : '';
    }
  },
  methods: {
    searchContact(event) {
      this.formSelectContact = this.formSelectContact.filter(contact => contact.label.includes(event.label));
    },
    getChantierById() {
      UserService.getChantier(this.$route.params.id).then( 
        response => {
          this.chantier = response.data;
          this.chantier.client.label = this.chantier.client.nom;
          this.chantier.client.value = this.chantier.client.id;
          if (this.chantier.contact) {
          this.chantier.contact.label = this.chantier.contact.prenom + ' ' + this.chantier.contact.nom;
          this.chantier.contact.value = this.chantier.contact.id;
          }
          this.chantier.pilote = this.userData.user ? {value : this.userData.user.id, label: this.userData.user.firstName + ' ' + this.userData.user.lastName } : '';
        },
        error => {this.content =(error.response && error.response.data) || error.message || error.toString();}
      );
    },
    async reload() {
       await UserService.getUserMe().then(
        response => {
          localStorage.setItem('userData', response.data);
        },
        error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
        }
      );
      this.$router.go();
    },
    async validationForm() {
        if("id" in this.$route.params) {
            const chantier = {
              client: '/api/clients/' + this.chantier.client.value,
              contact: this.chantier.contact ? '/api/contacts/' + this.chantier.contact.value : null,
              pilote: '/api/users/' + this.chantier.pilote.value,
              nom: this.chantier.nom,
              dureeHVendu: this.chantier.dureeHReel,
              dureeHReel: this.chantier.dureeHReel,
              dateDebutPrevu: this.chantier.dateDebutPrevu,
              dateFinPrevu: this.chantier.dateFinPrevu,
              dateDebutReel: this.chantier.dateDebutPrevu,
              datefinReel: this.chantier.dateFinPrevu,
              adresse1: this.chantier.adresse1,
              adresse2: this.chantier.adresse2,
              cp: this.chantier.cp,
              ville: this.chantier.ville,
            };
            UserService.patchChantier(this.$route.params.id, chantier).then(
              () => {
                UserService.getUserMe().then(
                  response => {
                    localStorage.setItem('userData', response.data);
                  },
                  error => {
                    this.content = (error.response && error.response.data) || error.message || error.toString();
                  }
                );
              },
            );
            document.getElementById('buttonChantier').click();
            document.getElementById('closeModalChantier').click();
            this.reload();
            
        } else {
            let duree = null;
            let dureeHReel = null;
            let hours = null;
            let minutes = null;
            let hourDec = null;
            if (this.chantier.dureeHReel != null) {
              if (this.chantier.dureeHReel.match(/^\d{2}[-.:hH]\d{2}$/)) {
                if (this.chantier.dureeHReel.match(/(2[0-3]|[0][0-9]|1[0-9])[-.:hH]([0-5][0-9])/)) {
                  duree = this.chantier.dureeHReel.replace('.', ',');
                  duree = duree.replace('-', ',');
                  duree = duree.replace('h', ',');
                  duree = duree.replace('H', ',');
                  duree = parseFloat(duree);

                  hours = Math.floor(duree / 60);
                  minutes = duree % 60;
                  hourDec = minutes / 60;
                  dureeHReel = Math.round(hours + hourDec, 2);
                  dureeHReel = dureeHReel.toString();
                  
                }
              }
            }
            const chantier = {
              client: '/api/clients/' + this.chantier.client.value,
              contact: '/api/contacts/' + this.chantier.contact.value,
              pilote: '/api/users/' + this.chantier.pilote.value,
              nom: this.chantier.nom,
              dureeHVendu: dureeHReel,
              dureeHReel: dureeHReel,
              dateDebutPrevu: this.chantier.dateDebutPrevu,
              dateFinPrevu: this.chantier.dateFinPrevu,
              dateDebutReel: this.chantier.dateDebutPrevu,
              datefinReel: this.chantier.dateFinPrevu,
              adresse1: this.chantier.adresse1,
              adresse2: this.chantier.adresse2,
              cp: this.chantier.cp,
              ville: this.chantier.ville,
            };
            await UserService.postChantier(chantier).then(
              res => {
                let chantierId = res.data['@id'];
                this.chantierNewId = chantierId.slice(15);
                  UserService.getUserMe().then(
                    response => {
                      localStorage.setItem('userData', response.data);
                    },
                    error => {
                      this.content = (error.response && error.response.data) || error.message || error.toString();
                    }
                  );
              },
            );
            document.getElementById('closeModalChantier').click();
            router.push('/chantiers/' + this.chantierNewId);
            this.reload();
        }
    },  
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

