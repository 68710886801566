<template>
  <div>

    <Header />

    <div class="page-content-wrapper">
      <div class="container mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 py-2 px-3 rounded"  style="margin-top:70px;">
            <li class="breadcrumb-item"><a href="/home"><i class="fas fa-chevron-left"></i><span class="ml-2">&nbsp; &nbsp;</span> Accueil</a></li>
            <li class="breadcrumb-item"><a href="/chantiers">Chantiers</a></li>
          </ol>
        </nav>
      </div>
      
      <div class="container mb-3">
        <div class="card bg-primary bg-img" style="background-image: url('../img/core-img/1.png')">
          <div class="card-body text-center py-3">
            <h6 class="mb-0 text-white line-height-1  text-center">{{chantier.nom}}</h6>
          <!--  <a href="#" class="ps-3" data-bs-toggle="modal" data-bs-target="#modalUpdateChantier"><i class="card-btn fas fa-pencil-alt text-white"></i></a> -->
          </div>
        </div>
      </div>

      <!-- modal update chantier -->
      <div class="add-new-contact-modal modal fade px-0" id="modalUpdateChantier" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="addnewcontactlabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body">
              <div class="d-flex align-items-center justify-content-between">
                <button class="btn btn-close p-1 ms-auto me-0" type="button" id="closeModalChantier" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <Form :type="formType" />
            </div>
          </div>
        </div>
      </div>

      <div class="container mt-3 mb-3">
        <button v-if="isStartOperation === 'true'" class="btn btn-lg btn-warning text-white w-100" id="endOperation" v-on:click="endOperation()">Fin d'activité : {{ operationChantierLigne != null ? operationChantierLigne.nom : chantier.nom }}</button>
        <a v-else href="#" class="btn btn-lg btn-success w-100" data-bs-toggle="modal" data-bs-target="#modalNewStart">Début d'activité</a>                      
      </div>

        <!-- modal > new start temps passé -->
        <div class="add-new-contact-modal modal fade px-0" id="modalNewStart" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modalNewStart" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-body">
                <div class="d-flex align-items-center justify-content-between">
                  <button class="btn btn-close p-1 ms-auto me-0" type="button" id="closeModalNewStart" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <FormStartOperation />
              </div>
            </div>
          </div>
        </div>

      <!-- infos lignes -->
      <div v-if="chantierLignes.length > 0 " class="container mb-3">
        <div class="card bg-info rounded-0 rounded-top">
          <div class="py-1 text-center">
            <h5 class="pt-2 text-white text-center">Lignes</h5>
          </div>
        </div>
        <div class="card rounded-0 rounded-bottom">
          <ul v-if="chantierLignes.length > 0 " class="ps-0 chat-user-list rounded-0">
          <li v-for="(ligne, index) in chantierLignes" :key="ligne.id" class="rounded-0 px-3 pt-2 pb-1 chat-unread text-left" v-bind:class="{ 'rounded-bottom': index === (chantierLignes.length-1) }">
            <a class="d-flex" v-bind:href="'/chantier_lignes/' + ligne.id">
              
              <div class="chat-user-info">
                <h6 class="mt-2 mb-1">
                  <span v-for="(niveau, index) in ligne.niveau" :key="index" class="mr-2">&nbsp;</span>
                  <i v-if="(ligne.niveau > 0 && (ligne.dureeHReel == '0.00' || ligne.dureeHReel == null))" class="fas fa-circle text-danger">&nbsp;</i>
                  <i v-if="(ligne.niveau > 0 && (ligne.dureeHReel + 0) > 0 && ligne.isDone == false)" class="fas fa-circle text-warning">&nbsp;</i>
                  <i v-if="(ligne.niveau > 0 && ligne.isDone == true)" class="fas fa-circle text-success">&nbsp;</i>
                  {{ligne.nom}}
                </h6>

                <div class="last-chat">
                  <div class="row">
                    <div class="col-10 text-left">
                      <p class="mb-2">
                        
                      </p>
                    </div>
                    <div class="col-2">
                      <p class="mb-2">
                        
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </a>
            <div class="dropstart chat-options-btn">
              <a v-bind:href="'/chantier_lignes/' + ligne.id" class="text-rbo">
                <i class="fas fa-chevron-right"></i>
              </a>
            </div>
          </li>
        </ul>
        <div v-else class="card bg-danger rounded-0 rounded mt-2">
          <div class="card-body text-center py-3">
            <h6 class="mb-0 text-white line-height-1">Aucune ligne</h6>
          </div>
        </div>
        </div>
      </div>


      <!-- infos chantier -->
      <div class="container mb-3">
        <div class="card bg-info rounded-0 rounded-top">
          <div class="py-1 text-center">
            <h5 class="pt-2 text-white text-center">Informations</h5>
          </div>
        </div>
        <div class="card rounded-0 rounded-bottom">
          <ul class="list-group">
            <li class="list-group-item"><b>Code : </b> {{chantier.code}}</li>
            <li class="list-group-item"><b>Pilote : </b> {{pilote.prenom}} {{pilote.nom}}</li>
            <li class="list-group-item"><b>Date prévue : </b> du {{chantier.dateDebutPrevu|formatDate}} au <span v-if="chantier.dateFinPrevu">{{chantier.dateFinPrevu|formatDate}}</span><span v-else> ?</span></li>
            <li class="list-group-item">
              <div class="d-flex justify-content-between">
                <div>
                    <b>Avancement : </b>
                    <span v-if="chantier.avancement == null" class="badge bg-warning">Non défini</span>
                    <span v-else></span>
                </div>
                  <a v-if="chantierLignes.length == 0" href="#" class="" data-bs-toggle="modal" data-bs-target="#modalAvancement"><span class="badge bg-success"><i class="bi bi-arrow-repeat"></i></span></a>
                </div>
              <div v-if="chantier.avancement" class="progress mt-2">
                  <div class="progress-bar bg-success" role="progressbar" v-bind:style="{width: chantier.avancement + '%'}" v-bind:aria-valuenow="chantier.avancement" aria-valuemin="0" aria-valuemax="100"></div>
                  <span class="justify-content-center d-flex position-absolute w-100 small">{{chantier.avancement}}%</span>
              </div>
            </li>
            <li class="list-group-item">
              <b>Temps passés : </b>
              <div class="progress mt-1">
                <div class="progress-bar bg-success" role="progressbar" v-bind:style="{width: pourcentageDuree + '%'}" v-bind:aria-valuenow="pourcentageDuree" aria-valuemin="0" aria-valuemax="100"></div>
                <span class="justify-content-center d-flex position-absolute w-100 small">{{chantier.dureeHReel ? chantier.dureeHReel : '0'}} H passées / {{chantier.dureeHVendu}} H vendues</span>
              </div>
            </li>
            <li class="list-group-item"><b>Zone : </b> <span v-if="chantier.zone">Z{{chantier.zone}}</span><span v-else class="badge bg-warning">Non définie</span></li>
            <li class="list-group-item d-flex justify-content-between">
              <div class="d-flex"><b>Statut : </b><Statuts :status="chantier.statut" /></div>              
            </li>
            <li class="list-group-item"><b>Terminé : </b> 
              <span v-if="chantier.isDone" class="badge bg-success">OUI</span> 
              <a v-if="chantierLignes.length == 0 && chantier.isDone == false" href="#" class="badge bg-warning" data-bs-toggle="modal" data-bs-target="#modalDoneChantier">NON</a>
              <span v-if="chantierLignes.length > 0 && chantier.isDone == false" class="badge bg-warning">NON</span> 
            </li>
          </ul>
        </div>
      </div>

      <!-- modal > update avancement -->
      <div class="add-new-contact-modal modal fade px-0" id="modalAvancement" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modalAvancement" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-body">
                <div class="d-flex align-items-center justify-content-between">
                  <button class="btn btn-close p-1 ms-auto me-0" type="button" id="closeModalAvancement" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <ChantierAvancementForm />
              </div>
            </div>
          </div>
        </div>

        <!-- modal > confirmation clôture chantier -->
      <div class="add-new-contact-modal modal fade px-0" id="modalDoneChantier" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modalDoneChantier" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-body">
                <div class="d-flex align-items-center justify-content-between">
                  <button class="btn btn-close p-1 ms-auto me-0" type="button" id="closeModalDoneChantier" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <ChantierClotureForm />
              </div>
            </div>
          </div>
        </div>

      <!-- infos client -->
      <div class="container mb-3">
        <div class="card bg-info rounded-0 rounded-top">
          <div class="py-1 text-center">
            <h5 class="pt-2 text-white text-center">Client</h5>
          </div>
        </div>
        <div class="card rounded-0 rounded-bottom">
          <ul class="list-group">
            <li class="list-group-item"><b>Client : </b> {{client.nom}}</li>
            <li  v-if="chantier.contact" class="list-group-item"><b>Contact : </b> {{contact.prenom}} {{contact.nom}}</li>
            <li  v-if="chantier.contact" class="list-group-item d-flex justify-content-between">
              <div><b>Téléphone bureau : </b> <span v-if="contact.telBureau">{{contact.telBureau}} </span><span v-else class="badge bg-warning">Non défini </span></div> 
              <div v-if="contact.telBureau">
              <a v-bind:href="'tel:' + contact.telBureau">
                <span class="badge bg-info rounded-pill py-2" style="margin-left: 1rem;"><i class="fas fa-phone-alt"></i></span></a>
              </div>
            </li>
            <li  v-if="chantier.contact" class="list-group-item d-flex justify-content-between">
              <div><b>Téléphone mobile : </b> <span v-if="contact.telMobile">{{contact.telMobile}} </span><span v-else class="badge bg-warning">Non défini </span></div> 
              <div v-if="contact.telMobile">
              <a v-bind:href="'tel:' + contact.telMobile">
                <span class="badge bg-info rounded-pill py-2" style="margin-left: 1rem;"><i class="fas fa-phone-alt"></i></span></a>
              <a v-bind:href="'sms:' + contact.telMobile">
                <span class="badge bg-info rounded-pill py-2" style="margin-left: 1rem;">SMS</span></a>
              </div>
            </li>
            <li  v-if="chantier.contact" class="list-group-item d-flex justify-content-between">
              <div><b>Email : </b><span v-if="contact.email">{{contact.email}}</span><span v-else class="badge bg-warning">Non défini</span></div>
              <div v-if="contact.email">
              <a v-bind:href="'mailto:' + contact.email">
              <span class="badge bg-info rounded-pill py-2" style="margin-left: 1rem;"><i class="fas fa-envelope"></i></span></a>
              </div>
            </li>
            <li class="list-group-item d-flex">
              <div class="col-3"><b>Adresse : </b></div>
              <div class="col-9 text-left">
              <span>{{chantier.adresse1}}<br></span>
              <span v-if="chantier.adresse2">{{chantier.adresse2}}<br></span>
              <span>{{chantier.cp}} {{chantier.ville}}</span>
              </div>
            </li>
          </ul>
        </div>
      </div>

    </div>

    <div class="pb-3"></div>

    <Footer />

  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Statuts from '@/components/ChantierStatuts.vue'
import Form from '@/components/ChantierForm.vue'
import FormStartOperation from '@/components/StartOperationForm.vue'
import UserService from '../services/user.service';
import ChantierAvancementForm from '@/components/ChantierAvancementForm.vue'
import ChantierClotureForm from '@/components/ChantierClotureForm.vue'

export default {
  name: 'Chantier',
  components: {
    Header,
    Footer,
    Statuts,
    Form,
    FormStartOperation,
    ChantierAvancementForm,
    ChantierClotureForm
  },
  data: function() {
    return {
      formType: '',
      chantier: [],
      userData: [],
      pilote: [],
      contact: [],
      client: [],
      chantierLignes: [],
      id: this.$route.params.id,
      urlUploads: this.$urlUploads,
      urlWeb: this.$urlWeb,
      classLigne: null,
      isStartOperation: null,
      operationChantierLigne: [],
      pourcentageDuree: 0,
    }
  },
  filters: {
    formatDate: function (value) {
      if (!value) return ''
      value = new Date(value).toLocaleDateString();
      return value;
    }
  }, 
  async created() {

    //chantier
    await UserService.getChantier(this.$route.params.id).then((response) => {
        this.chantier = response.data;
        this.pilote = this.chantier.pilote;
        this.client = this.chantier.client;
        this.contact = this.chantier.contact;
        this.pourcentageDuree = (this.chantier.dureeHReel / this.chantier.dureeHVendu) * 100;
      },
      error => {
        this.content = (error.response && error.response.data) || error.message || error.toString();
      }
    );
    console.log(this.chantier)

    // operation
    this.isStartOperation = localStorage.getItem('isStartOperation');
    this.operationId = localStorage.getItem('startOperationId');
      if (this.operationId != null) {
        await UserService.getOperation(this.operationId).then(
          response => {
            this.operation = response.data;
            this.operationChantierLigne = this.operation.chantierLigne;
          },
          error => {
            this.content = (error.response && error.response.data) || error.message || error.toString();
          }
        );
      }

    // chantierLignes
    await UserService.getChantierLignes('?cId=' + this.$route.params.id).then((response) => {
        this.chantierLignes = response.data['hydra:member'];
        if (parseFloat(this.chantierLignes.dureeHReel) == 0) {this.classLigne = 'text-danger'}
        if (parseFloat(this.chantierLignes.dureeHReel) > 0) {this.classLigne = 'text-warning'}
        if (this.chantierLignes.isDone == true) {this.classLigne = 'text-success'}
      },
      error => {
        this.content = (error.response && error.response.data) || error.message || error.toString();
      }
    );
    this.userData = JSON.parse(localStorage.getItem('userData'));
  },
  methods: {
    async reload() {
       await UserService.getUserMe().then(
        response => {
          localStorage.setItem('userData', response.data);
        },
        error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
        }
      );
      this.$router.go();
    },
    async endOperation() {
      let operationId = localStorage.getItem('startOperationId');
      if (operationId) {
        await UserService.getOperation(operationId).then(
          response => {
            this.operation = response.data;
            let fin = new Date();
            let finDate = fin.toLocaleDateString('en');
            let finTime = fin.toTimeString();
            const operation = {
              fin: (new Date(finDate + ' ' + finTime + ' UTC')).toJSON(),
            };
             UserService.patchOperation(operationId, operation).then(
              response => {
                this.operation = response.data;
                localStorage.setItem('startOperationId', null);
                localStorage.setItem('isStartOperation', false);
              },
              error => {
                this.content = (error.response && error.response.data) || error.message || error.toString();
              }
            );
            this.reload();
          },
          error => {
            this.content = (error.response && error.response.data) || error.message || error.toString();
          }
        );
      }
    }
  },
  watch: { 
   '$route.query.type': {
      handler: function(type) {
        this.formType = type;
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style>
.text-justify {
  text-align: justify;
}
.list-group-item {
  text-align: left;
}
.text-right {
  text-align: right;
}
</style>