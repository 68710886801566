<template>
  <div>

    <Header />

    <div class="page-content-wrapper">
      <div class="container mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 py-2 px-3 rounded"  style="margin-top:70px;">
            <li class="breadcrumb-item"><a href="/home"><i class="fas fa-chevron-left"></i><span class="ml-2">&nbsp; &nbsp;</span> Accueil</a></li>
            <li class="breadcrumb-item"><a href="/chantiers">Chantiers</a></li>
            <li class="breadcrumb-item"><a v-bind:href="'/chantiers/' + chantier.id">{{chantier.nom}}</a></li>
          </ol>
        </nav>
      </div>
      
      <div class="container mb-3">
        <div class="card bg-primary bg-img" style="background-image: url('../img/core-img/1.png')">
          <div class="card-body text-center py-3">
            <h6 class="mb-0 text-white line-height-1">{{ligne.nom}}</h6>
          </div>
        </div>
      </div>

      <div class="container mt-3 mb-3">
        <button v-if="isStartOperation === 'true'" class="btn btn-lg btn-warning text-white w-100" id="endOperation" v-on:click="endOperation()">Fin d'activité : {{ operationChantierLigne != null ? operationChantierLigne.nom : chantier.nom }}</button>
        <a v-else href="#" class="btn btn-lg btn-success w-100" data-bs-toggle="modal" data-bs-target="#modalNewStart">Début d'activité</a>                      
      </div>

        <!-- modal > new start temps passé -->
        <div class="add-new-contact-modal modal fade px-0" id="modalNewStart" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modalNewStart" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-body">
                <div class="d-flex align-items-center justify-content-between">
                  <button class="btn btn-close p-1 ms-auto me-0" type="button" id="closeModalNewStart" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <FormStartOperation />
              </div>
            </div>
          </div>
        </div>

      <!-- infos lignes -->
      <div v-if="ligneFils.length > 0 " class="container mb-3">
        <div class="card bg-info rounded-0 rounded-top">
          <div class="py-1 text-center">
            <h5 class="pt-2 text-white text-center">Lignes</h5>
          </div>
        </div>
        <div class="card rounded-0 rounded-bottom">
          <ul v-if="ligneFils.length > 0 " class="ps-0 chat-user-list rounded-0">
          <li v-for="(fils, index) in ligneFils" :key="fils.id" class="rounded-0 px-3 pt-2 pb-1 chat-unread text-left" v-bind:class="{ 'rounded-bottom': index === (ligneFils.length-1) }">
            <a class="d-flex" v-bind:href="'/chantier_lignes/' + fils.id">
              
              <div class="chat-user-info">
                <h6 class="mt-2 mb-1">{{fils.nom}}</h6>

                <div class="last-chat">
                  <div class="row">
                    <div class="col-10 text-left">
                      <p class="mb-2">
                        
                      </p>
                    </div>
                    <div class="col-2">
                      <p class="mb-2">
                        
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </a>
            <div class="dropstart chat-options-btn">
              <a v-bind:href="'/chantier_lignes/' + fils.id" class="text-rbo">
                <i class="fas fa-chevron-right"></i>
              </a>
            </div>
          </li>
        </ul>
        <div v-else class="card bg-danger rounded-0 rounded mt-2">
          <div class="card-body text-center py-3">
            <h6 class="mb-0 text-white line-height-1">Aucune ligne</h6>
          </div>
        </div>
        </div>
      </div>

      <!-- infos chantier -->
      <div class="container mb-3">
        <div class="card bg-info rounded-0 rounded-top">
          <div class="py-1 text-center">
            <h5 class="pt-2 text-white text-center">Informations</h5>
          </div>
        </div>
        <div class="card rounded-0 rounded-bottom">
          <ul class="list-group">
            <li class="list-group-item"><b>Chantier : </b> <a v-bind:href="'/chantiers/' + chantier.id">{{chantier.nom}}</a></li>
            <li class="list-group-item"><b>Code : </b> {{ligne.code}}</li>
            <!--
            <li class="list-group-item"><b>Heures prévues : </b>{{ligne.dureeHVendu}} H</li>
            <li class="list-group-item"><b>Heures passées : </b>{{ligne.dureeHReel}} H</li>
            -->
            <li class="list-group-item">
              <div class="d-flex justify-content-between">
                <div>
                    <b>Avancement : </b>
                    <span v-if="ligne.avancement == null" class="badge bg-warning">Non défini</span>
                    <span v-else></span>
                </div>
                  <a href="#" class="" data-bs-toggle="modal" data-bs-target="#modalAvancement"><span class="badge bg-success"><i class="bi bi-arrow-repeat"></i></span></a>
                </div>
              <div v-if="ligne.avancement" class="progress mt-2">
                  <div class="progress-bar bg-success" role="progressbar" v-bind:style="{width: ligne.avancement + '%'}" v-bind:aria-valuenow="ligne.avancement" aria-valuemin="0" aria-valuemax="100"></div>
                  <span class="justify-content-center d-flex position-absolute w-100 small">{{ligne.avancement}}%</span>
              </div>
            </li>
            <li class="list-group-item">
              <b>Temps passés : </b>
              <div class="progress mt-1">
                <div class="progress-bar" role="progressbar" v-bind:class="'bg-' + pourcentageClass" v-bind:style="{width: pourcentageDuree + '%'}" v-bind:aria-valuenow="pourcentageDuree" aria-valuemin="0" aria-valuemax="100"></div>
                <span class="justify-content-center d-flex position-absolute w-100 small">{{ligne.dureeHReel ? ligne.dureeHReel : '0'}} H passées / {{ligne.dureeHVendu}} H vendues</span>
              </div>
            </li>
            <li class="list-group-item"><b>Date : </b> du {{ligne.dateDebut|formatDate}} au <span v-if="ligne.dateFin">{{ligne.dateFin|formatDate}}</span><span v-else> ?</span></li>
            <li class="list-group-item d-flex justify-content-between">
              <div class="d-flex"><b>Statut : </b><Statuts :status="ligne.statut" /></div>              
            </li>
            <li class="list-group-item"><b>Terminé : </b> 
              <span v-if="ligne.isDone" class="badge bg-success">OUI</span> 
              <a v-else href="#" class="badge bg-warning" data-bs-toggle="modal" data-bs-target="#modalDoneLigne">NON</a>
            </li>
          </ul>
        </div>
      </div>

      <!-- modal > update avancement -->
      <div class="add-new-contact-modal modal fade px-0" id="modalAvancement" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modalAvancement" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-body">
                <div class="d-flex align-items-center justify-content-between">
                  <button class="btn btn-close p-1 ms-auto me-0" type="button" id="closeModalAvancement" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <LigneAvancement />
              </div>
            </div>
          </div>
        </div>

      <!-- modal > confirmation clôture ligne -->
      <div class="add-new-contact-modal modal fade px-0" id="modalDoneLigne" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modalDoneLigne" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-body">
                <div class="d-flex align-items-center justify-content-between">
                  <button class="btn btn-close p-1 ms-auto me-0" type="button" id="closeModalDoneLigne" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <LigneCloture />
              </div>
            </div>
          </div>
        </div>

    </div>

    <div class="pb-3"></div>

    <Footer />

  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Statuts from '@/components/ChantierStatuts.vue'
import FormStartOperation from '@/components/StartOperationForm.vue'
import LigneCloture from '@/components/LigneClotureForm.vue'
import LigneAvancement from '@/components/LigneAvancementForm.vue'
import UserService from '../services/user.service';

export default {
  name: 'ChantierLigne',
  components: {
    Header,
    Footer,
    Statuts,
    FormStartOperation,
    LigneCloture,
    LigneAvancement,
  },
  data: function() {
    return {
      formType: '',
      chantier: [],
      userData: [],
      ligne: [],
      ligneFils: [],
      id: this.$route.params.id,
      urlUploads: this.$urlUploads,
      urlWeb: this.$urlWeb,
      isStartOperation: null,
      operationChantierLigne: [],
      pourcentageDuree: 0,
      pourcentageClass: 'success',
    }
  },
  filters: {
    formatDate: function (value) {
      if (!value) return ''
      value = new Date(value).toLocaleDateString();
      return value;
    }
  }, 
  async created() {
    // operation
    this.isStartOperation = localStorage.getItem('isStartOperation');
    this.operationId = localStorage.getItem('startOperationId');
      if (this.operationId != "null") {
        await UserService.getOperation(this.operationId).then(
          response => {
            this.operation = response.data;
            this.operationChantierLigne = this.operation.chantierLigne;
          },
          error => {
            this.content = (error.response && error.response.data) || error.message || error.toString();
          }
        );
      }

    //chantier
    await UserService.getChantierLigne(this.$route.params.id).then((response) => {
        this.ligne = response.data;
        this.chantier = this.ligne.chantier;
        this.ligneFils = this.ligne.fils;  
        this.pourcentageDuree = (this.ligne.dureeHReel / this.ligne.dureeHVendu) * 100;
        if (this.pourcentageDuree < 3) { this.pourcentageClass = 'danger';}
        if (this.pourcentageDuree > 90) { this.pourcentageClass = 'warning';}
        if (this.pourcentageDuree > 100) { this.pourcentageClass = 'danger';}
        if (this.ligne.isDone == true) { this.pourcentageClass = 'secondary';}
        console.log(this.ligne)
      },
      error => {
        this.content = (error.response && error.response.data) || error.message || error.toString();
      }
    );
    this.userData = JSON.parse(localStorage.getItem('userData'));
  },
  watch: { 
   '$route.query.type': {
      handler: function(type) {
        this.formType = type;
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style>
.text-justify {
  text-align: justify;
}
.list-group-item {
  text-align: left;
}
.text-right {
  text-align: right;
}
</style>