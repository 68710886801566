<template>
  <div>

    <!-- Header Area -->
    <div class="header-area" id="headerArea">
      <div class="container">
        <div class="header-content header-style-five position-relative d-flex align-items-center justify-content-between">
          <!-- Logo -->
          <div class="logo-wrapper"><a href="/home"><img src="/img/oktime-logo.png"/></a></div>
          <!-- Back Button -->
          <div v-if="urlBack" class="back-button">
            <a :href="urlBack"><i class="fas fa-chevron"></i></a>
          </div>
          <!-- Page Title -->
          <div class="page-heading">
            <h6 class="mb-0">{{title}}</h6>
          </div>
          <!-- Navbar Toggler -->
          <div class="navbar--toggler" id="affanNavbarToggler" data-bs-toggle="offcanvas" data-bs-target="#affanOffcanvas" aria-controls="affanOffcanvas"><span class="d-block"></span><span class="d-block"></span><span class="d-block"></span></div>
        </div>
      </div>
    </div>

    <!-- Offcanvas -->
    <div class="offcanvas offcanvas-start" id="affanOffcanvas" data-bs-scroll="true" tabindex="-1" aria-labelledby="affanOffcanvsLabel">
      <button class="btn-close btn-close-white text-reset" type="button" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      <div class="offcanvas-body p-0">
        <div class="sidenav-wrapper">
          <div class="sidenav-profile bg-rbo">
            <div class="sidenav-style1"></div>
            <!-- User -->
            <div v-if="loggedIn">
                <img src="/img/avatar.png" class="avatar-img rounded-circle" alt="photo">
                <div class="user-info">
                  <h6 class="user-name mb-0 mt-3">{{userData.user.firstName}} {{userData.user.lastName}}</h6>
                </div>
            </div>
            <!-- Logo -->
            <div v-if="!loggedIn" class="container">
                  <div>
                   <img src="" class="" alt="photo" style="max-height: 200px;">
                  </div>
            </div>
          </div>

          <!-- Sidenav Nav -->
          <ul class="sidenav-nav ps-0">
            <li><a href="/home"><i class="bi bi-house-door"></i>Accueil</a></li>
            <li><a href="/chantiers"><i class="bi bi-truck"></i>Chantiers</a></li>
            <li><a href="/journees"><i class="bi bi-clock"></i>Journée</a></li>
            <li v-if="!loggedIn"><a href="/login"><i class="bi bi-box-arrow-in-right"></i>Connexion</a></li>
            <li v-if="loggedIn"><a href="/login" @click.prevent="logOut"><i class="bi bi-box-arrow-right"></i>Déconnexion</a></li>
            <li v-if="loggedIn"><a href="/home" @click.prevent="deleteCaches"><i class="bi bi-trash"></i>Supprimer le cache</a></li>

          </ul>
          
          <!-- Social Info 
          <div class="social-info-wrap">
            <a href="https://facebook.com" target="_blank"><i class="bi bi-facebook"></i></a>
            <a href="https://linkedin.com" target="_blank"><i class="bi bi-linkedin"></i></a>
            <a href="https://twitter.com" target="_blank"><i class="bi bi-twitter"></i></a>
          </div>
          -->
          
          <!-- Copyright Info -->
          <div class="copyright-info">
            <p>
                &copy; 2022 -
                Application développée
                par<a href="https://www.betterb.fr" target="_blank">BetterB</a>
                - Version {{version}}
            </p>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Header',
  props: ['title', 'urlBack'],
  data: function() {
    return {
        version: this.$version,
        loggedIn: this.$store.state.auth.status.loggedIn,
        userData: [],
    }
  },
  filters: {
    formatDecimal: function (value) {
      if (!value) return '0'
      value = (value).toFixed(2);
      return value;
    }
  }, 
  async created() {
    if (localStorage.getItem('userData')) {
      this.userData = JSON.parse(localStorage.getItem('userData'));
    }
  },
  methods: {
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    },
    deleteCaches() {
      const staticCacheName = 'cache-v' + this.$version;
      const dynamicCacheName = 'runtimeCache-v' + this.$version;
      caches.delete(staticCacheName).then(function() {});
      caches.delete(dynamicCacheName).then(function() {});
      this.$router.go();
    }
  }
}
</script>

<style>
.header-title {
    color: #153760;
    padding-top: 0.4rem;
    font-weight: bold;
}
h6 {
    font-size: 1.2rem;
    font-weight: bolder;
}
</style>