<template>
  <div id="operationForm">
        <div class="card mb-3">
          <div class="card-body">

            <!-- form -->
            <div class="mt-3">
              <h6 class="mb-3 text-center">Enregistrer un temps passé</h6>

              <FormulateForm @submit="validationForm">
            
                <div class="text-left mt-4 mb-1"><label>Chantier</label></div>
                <Multiselect
                  v-model="operation.chantier" 
                  :options="formSelectChantier"
                  :searchable="true" 
                  :close-on-select="true" 
                  :show-labels="false" 
                  :custom-label="formSelectChantier.label"
                  label="label"
                  track-by="value"
                  placeholder="Selectionner le chantier"
                  @select="searchChantierLigne($event)"
                  ></Multiselect>
                  
                <div class="mb-4"></div>

                <div class="text-left mt-4 mb-1"><label>Ligne</label></div>
                <Multiselect
                  v-model="operation.chantierLigne" 
                  :options="formSelectChantierLigne"
                  :searchable="true" 
                  :close-on-select="true" 
                  :show-labels="false" 
                  :custom-label="formSelectChantierLigne.label"
                  label="label"
                  track-by="value"
                  placeholder="Selectionner la ligne du chantier"
                  ></Multiselect>
                  
                <div class="mb-4"></div>

                <div v-if="error" class="alert alert-warning w-100">
                  <i class="fas fa-exclamation-triangle"></i>
                  {{error}}
                </div>
                
                <FormulateInput
                  type="submit"
                  label="Valider"
                  id="buttonStartOperation"
                  input-class="btn btn-primary w-100 text-white mt-3"
                />
                
              </FormulateForm>

            </div>

          </div>
        </div>

  </div>
</template>

<script>
//import router from '../router'
import UserService from '../services/user.service'

var temp = Object.freeze({
  chantier: '',
  chantierLigne: null,
  debut: '',
  hDebut: '',
  hFin: '',
  dureeMin: null,
  dureeMinH: null,
  dureeMinM: null,
});

export default {
  name: 'Form',
  components: {
  },
  data () {
    return {
      userData: [],
      formSelectChantier: [],
      formSelectChantierLigne: [],
      error: null,
      operation: Object.assign({}, temp),
      editing: false,
      isDuree: false,
      operationNewId: null,
      tomorrow: null,
      step: null,
    }
  },
  async created() {
    this.userData = JSON.parse(localStorage.getItem('userData'));

    if (this.userData.user.siteDureePas != null) {
      this.step = this.userData.user.siteDureePas;
    } else {
      this.step = 15;
    }
    if ("id" in this.$route.params) {
      if (this.$route.name === "chantier") {
        await UserService.getChantier(this.$route.params.id).then((response) => {
          this.operation.chantier = {value: response.data.id, label: response.data.nom};
          this.formSelectChantier = [{value: response.data.id, label: response.data.nom}];
          // chantierLignes
          UserService.getChantierLignesForm('?q=form&cId=' + this.$route.params.id).then((response) => {
            this.formSelectChantierLigne = response.data['hydra:member'];
          },
            error => {
              this.content = (error.response && error.response.data) || error.message || error.toString();
            }
          );
        },
        error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
        }
        );
      }
      
      if (this.$route.name === "chantierLigne") {
        await UserService.getChantierLigne(this.$route.params.id).then((response) => {
          this.operation.chantier = {value: response.data.chantier.id, label: response.data.chantier.nom};
          this.formSelectChantier = [{value: response.data.chantier.id, label: response.data.chantier.nom}];  
          
          this.operation.chantierLigne = {value: response.data.id, label: response.data.nom};
          this.formSelectChantierLigne = [{value: response.data.id, label: response.data.nom}];
        },
        error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
        });
      }
    } else {
      await UserService.getChantiersForm().then(
      response => {
        this.formSelectChantier = response.data['hydra:member'];
      },
      error => {
        this.content = (error.response && error.response.data) || error.message || error.toString();
      }
      );
    }
    this.tomorrow = {
      disabledDates: {
        from: new Date(),
      },
    }

  },
  watch: {
    type(val) {
      if (val == 'add') {
        this.operation = Object.assign({}, temp);
      }
    }
  },
  mounted() {
    if ("id" in this.$route.params) {
      this.editing = true;
    } else {
      this.operation.debut = new Date();
    }
  },
  methods: {
    searchChantierLigne(event) {
      // chantierLignes
      UserService.getChantierLignesForm('?q=form&cId=' + event.value).then((response) => {
        this.formSelectChantierLigne = response.data['hydra:member'];
      },
      error => {
        this.content = (error.response && error.response.data) || error.message || error.toString();
      }
    );
    },
    async reload() {
       await UserService.getUserMe().then(
        response => {
          localStorage.setItem('userData', response.data);
        },
        error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
        }
      );
      this.$router.go();
    },
    async validationForm() {
      let debut = new Date();
      let debutDate = debut.toLocaleDateString('en');
      let debutTime = debut.toTimeString();
      const operation = {
        chantier: '/api/chantiers/' + this.operation.chantier.value,
        chantierLigne: this.operation.chantierLigne != null ? '/api/chantier_lignes/' + this.operation.chantierLigne.value : null,
        debut: (new Date(debutDate + ' ' + debutTime + ' UTC')).toJSON(),
      };
      await UserService.postOperation(operation).then(
        res => {
          let operationId = res.data['@id'];
          this.operationNewId = operationId.slice(16);
          localStorage.setItem('startOperationId', this.operationNewId);
          localStorage.setItem('isStartOperation', true);

            UserService.getUserMe().then(
              response => {
                localStorage.setItem('userData', response.data);
              },
              error => {
                this.content = (error.response && error.response.data) || error.message || error.toString();
              }
            );
        },
      );
      document.getElementById('buttonStartOperation').click();
      document.getElementById('closeModalNewStart').click();
      this.reload();
    },  
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

