<template>
  <div>
    <!-- statut -->
    <div v-if="status != null">
      <span v-if="status == 'scheduled'" class="badge bg-info" style="margin-left: 10px;">Planifié</span>
      <span v-if="status == 'started'" class="badge bg-warning" style="margin-left: 10px;">Commencé</span>
      <span v-if="status == 'done'" class="badge bg-success" style="margin-left: 10px;">Terminé</span>
      <span v-if="status == 'closed'" class="badge bg-success" style="margin-left: 10px;">Clôturé</span>
      <span v-if="status == 'canceled'" class="badge bg-secondary" style="margin-left: 10px;">Annulé</span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ChantierStatuts',
  props: ['status'],
  data: function() {
    return {
    }
  },
  created() {
    
  },
  methods: {
  }
}
</script>

<style>
</style>