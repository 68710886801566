<template>
  <div>

    <Header/>

    <div class="page-content-wrapper">

      <div class="pt-3"></div>

      <div class="container">
            <div class="card bg-primary mb-3 bg-img" style="background-image: url('img/core-img/1.png')">
                <div class="card-body direction-rtl">
                    <h2 class="text-white mb-0">OKTIME</h2>
                </div>
            </div>
        </div>

        <div v-if="loggedIn && isNewVersion" class="container mt-3">
            <div class="card">
                <div class="card-body">
                    <div class="d-grid gap-2">
                        <button class="btn btn-warning  btn-lg text-white" type="button" data-bs-toggle="modal" data-bs-target="#modalNewVersion">Nouvelle version disponible</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="container mt-3">
            <div class="card">
                <div class="card-body">
                    <div class="d-grid gap-2">
                      <button v-if="isStartOperation === 'true'" class="btn btn-lg btn-warning text-white" id="endOperation" v-on:click="endOperation()">Fin d'activité : {{ chantierLigne != null ? chantierLigne.nom : chantier.nom }}</button>
                      <a v-else href="#" class="btn btn-lg btn-success" data-bs-toggle="modal" data-bs-target="#modalNewStart">Début d'activité</a>
                      
                    </div>
                </div>
            </div>
        </div>

        <!-- modal > new start temps passé -->
        <div class="add-new-contact-modal modal fade px-0" id="modalNewStart" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modalNewStart" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-body">
                <div class="d-flex align-items-center justify-content-between">
                  <button class="btn btn-close p-1 ms-auto me-0" type="button" id="closeModalNewStart" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <FormStartOperation />
              </div>
            </div>
          </div>
        </div>

        <div class="container mt-3">
            <div class="card">
                <div class="card-body">
                    <div class="d-grid gap-2">
                      <a  href="/chantiers" class="btn btn-lg btn-primary">Récapitulatif des chantiers</a>
                      <a href="#" class="btn btn-lg btn-success" data-bs-toggle="modal" data-bs-target="#modalNewChantier">Saisir un nouveau chantier</a>
                    </div>
                </div>
            </div>
        </div>
        <!-- modal > new chantier -->
      <div class="add-new-contact-modal modal fade px-0" id="modalNewChantier" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modalNewChantier" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body">
              <div class="d-flex align-items-center justify-content-between">
                <button class="btn btn-close p-1 ms-auto me-0" type="button" id="closeModalNewChantier" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <Form :type="formType" />
            </div>
          </div>
        </div>
      </div>

        <div class="container mt-3">
            <div class="card">
                <div class="card-body">
                    <div class="d-grid gap-2">
                      <a href="#" class="btn btn-lg btn-info" data-bs-toggle="modal" data-bs-target="#modalNewOperation">Saisir les temps passés</a>
                      <a href="#" class="btn btn-lg btn-warning text-white" data-bs-toggle="modal" data-bs-target="#modalNewJournee">Clôture de journée</a>
                      <a class="btn btn-lg btn-secondary" href="/journees">Récapitulatif de la semaine</a>
                    </div>
                </div>
            </div>
        </div>

        <!-- modal > new temps passé -->
        <div class="add-new-contact-modal modal fade px-0" id="modalNewOperation" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modalNewOperation" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-body">
                <div class="d-flex align-items-center justify-content-between">
                  <button class="btn btn-close p-1 ms-auto me-0" type="button" id="closeModalOperation" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <FormOperation :type="formType" />
              </div>
            </div>
          </div>
        </div>

        <!-- modal > new clôture journee -->
        <div class="add-new-contact-modal modal fade px-0" id="modalNewJournee" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modalNewJournee" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-body">
                <div class="d-flex align-items-center justify-content-between">
                  <button class="btn btn-close p-1 ms-auto me-0" type="button" id="closeModalJournee" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <FormJournee :type="formType" />
              </div>
            </div>
          </div>
        </div>


        <!-- Modal new version -->
            <div v-if="loggedIn && isNewVersion" class="modal fade" id="modalNewVersion" tabindex="-1" aria-labelledby="modalNewVersionLabel" aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                  <div class="modal-header">
                    <h6 class="modal-title" id="modalNewVersionLabel">Nouvelle version disponible</h6>
                    <button class="btn btn-close p-1 ms-auto" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    <p class="mb-2 text-left">
                      La version {{newVersion.code}} "{{newVersion.name}}" est disponible (version actuelle : {{this.$version}}).
                    </p>
                    <p class="mb-3 text-left">
                      Cette version propose les évolutions suivantes :
                    </p>
                    <div class="mb-2 text-left evolutionText" v-html="newVersion.detail"></div>
                  </div>
                  <div class="modal-footer">
                    <button class="btn btn-sm btn-secondary text-white" type="button" data-bs-dismiss="modal">Annuler</button>
                    <button @click="activateNewVersion" class="btn btn-sm btn-info text-white" type="button">Activer la nouvelle version</button>
                  </div>
                </div>
              </div>
            </div>

      <div class="pb-3"></div>

    </div>

    <Footer />

  </div>
</template>

<script>
import router from '../router'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Form from '@/components/ChantierForm.vue'
import FormOperation from '@/components/OperationForm.vue'
import FormStartOperation from '@/components/StartOperationForm.vue'
import FormJournee from '@/components/JourneeForm.vue'
import UserService from '../services/user.service';

export default {
  name: 'Home',
  data: function() {
    return {
      loggedIn: this.$store.state.auth.status.loggedIn,
      date: null,
      editing: false,
      formType: '',
      isNewVersion: false,
      newVersion: null,
      isStartOperation: null,
      operationId: null,
      operation: [],
      chantier: [],
      chantierLigne: [],
    }
  },
  components: {
    Header,
    Footer,
    Form,
    FormOperation,
    FormJournee,
    FormStartOperation,
  },
  async created() {
    if (!this.currentUser) {
      router.push('/login');
    }

    // operation
    this.isStartOperation = localStorage.getItem('isStartOperation');
    this.operationId = localStorage.getItem('startOperationId');
      if (this.operationId != "null") {
        await UserService.getOperation(this.operationId).then(
          response => {
            this.operation = response.data;
            this.chantier = this.operation.chantier;
            this.chantierLigne = this.operation.chantierLigne;
          },
          error => {
            this.content = (error.response && error.response.data) || error.message || error.toString();
          }
        );
      }


    if(this.loggedIn) {
      // last version
      await UserService.getLastVersion().then((response) => {
          let lastVersion = JSON.parse(response.data);
          if (lastVersion.length > 0 && lastVersion.code.slice(2) != this.$version.slice(2)) {
            this.isNewVersion = true;
            this.newVersion = lastVersion;
          }
        },
        error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
        }
      );
    }

    // userData
      await UserService.getUserMe().then(
        response => {
          localStorage.setItem('userData', response.data);
        },
        error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
        }
      );
  },
  mounted() {
    if ("id" in this.$route.params) {
      this.editing = true;
    } else {
      this.date = new Date();
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  methods: {
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.go();
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    },
    activateNewVersion() {
      const staticCacheName = 'cache-v' + this.$version;
      const dynamicCacheName = 'runtimeCache-v' + this.$version;
      caches.delete(staticCacheName).then(function() {});
      caches.delete(dynamicCacheName).then(function() {});
      this.$router.go();
    },
    async reload() {
       await UserService.getUserMe().then(
        response => {
          localStorage.setItem('userData', response.data);
        },
        error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
        }
      );
      this.$router.go();
    },
    async endOperation() {
      let operationId = localStorage.getItem('startOperationId');
      if (operationId) {
        await UserService.getOperation(operationId).then(
          response => {
            this.operation = response.data;
            let fin = new Date();
            let finDate = fin.toLocaleDateString('en');
            let finTime = fin.toTimeString();
            const operation = {
              fin: (new Date(finDate + ' ' + finTime + ' UTC')).toJSON(),
            };
             UserService.patchOperation(operationId, operation).then(
              response => {
                this.operation = response.data;
                localStorage.setItem('startOperationId', null);
                localStorage.setItem('isStartOperation', false);
              },
              error => {
                this.content = (error.response && error.response.data) || error.message || error.toString();
              }
            );
            this.reload();
          },
          error => {
            this.content = (error.response && error.response.data) || error.message || error.toString();
          }
        );
      }
    }
  },
   watch: { 
   '$route.query.type': {
      handler: function(type) {
        this.formType = type;
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style>
.text-justify {
  text-align: justify;
}
.avatar-img {
    width: 100%;
    height: 100%;
    max-height: 80px;
    max-width: 80px;
    vertical-align: middle;
    -o-object-fit: cover;
    object-fit: cover;
}
.rounded-circle {
    border-radius: 50% !important;
}
</style>
