<template>
  <div id="journeeForm">
        <div class="card mb-3">
          <div class="card-body">

            <!-- rem form -->
            <div class="mt-3">
              <h6 class="mb-3 text-center">{{ editing ? 'Modifier' : 'Enregistrer' }} une clôture de journée</h6>

              <FormulateForm @submit="validationForm">
                
                <FormulateInput
                    type="datepicker"
                    language="fr"
                    name="date"
                    label="Date début"
                    format="dd/MM/yyyy"
                    v-model="journee.date"
                    :disabled-dates="tomorrow.disabledDates"
                />

                <div class="text-left mt-4 mb-1"><label>Chantier</label></div>
                <Multiselect
                  v-model="journee.chantier" 
                  :options="formSelectChantier"
                  :searchable="true" 
                  :close-on-select="true" 
                  :show-labels="false" 
                  :custom-label="formSelectChantier.label"
                  label="label"
                  track-by="value"
                  placeholder="Selectionner le chantier"
                  ></Multiselect>
                  
                <div class="mb-4"></div>
                
                <div class="form-group text-left">
                  <label class="mb-2" for="primaryRadio">Zone</label>
                  <div class="single-plan-check shadow-sm active-effect">
                  <div class="form-check" style="padding-left: 0;">
                      <input v-model="radioZone" value="Z1" class="form-check-input" type="radio" name="exampleRadio" id="z1" style="margin-left:-.5em;">
                      <label class="form-check-label" for="z1">1</label>
                  </div>
                  <div class="form-check pl-0">
                      <input v-model="radioZone" value="Z2" class="form-check-input" type="radio" name="exampleRadio" id="z2" style="margin-left:-.5em;">
                      <label class="form-check-label" for="z2">2</label>
                  </div>
                  <div class="form-check">
                      <input v-model="radioZone" value="Z3" class="form-check-input" type="radio" name="exampleRadio" id="z3" style="margin-left:-.5em;">
                      <label class="form-check-label" for="z3">3</label>
                  </div>
                  <div class="form-check">
                      <input v-model="radioZone" value="Z4" class="form-check-input" type="radio" name="exampleRadio" id="z4" style="margin-left:-.5em;">
                      <label class="form-check-label" for="z4">4</label>
                  </div>
                  <div class="form-check">
                      <input v-model="radioZone" value="Z5" class="form-check-input" type="radio" name="exampleRadio" id="z5" style="margin-left:-.5em;">
                      <label class="form-check-label" for="z5">5</label>
                  </div>
                  <div class="form-check">
                      <input v-model="radioZone" value="Z6" class="form-check-input" type="radio" name="exampleRadio" id="z6" style="margin-left:-.5em;">
                      <label class="form-check-label" for="z6">6</label>
                  </div>
                  <div class="form-check">
                      <input v-model="radioZone" value="Z7" class="form-check-input" type="radio" name="exampleRadio" id="z7" style="margin-left:-.5em;">
                      <label class="form-check-label" for="z7">7</label>
                  </div>
                  <div class="form-check">
                      <input v-model="radioZone" value="Z8" class="form-check-input" type="radio" name="exampleRadio" id="z8" style="margin-left:-.5em;">
                      <label class="form-check-label" for="z8">8</label>
                  </div>
                  </div>
                </div>

                <div class="form-group  text-left">
                  <label class="mb-2" for="radio">Repas</label>
                  <div class="single-plan-check shadow-sm active-effect">
                    <div class="form-check mb-0">
                        <input v-model="radioRepas" value="Panier" class="form-check-input" type="radio" name="repas" id="Panier">
                        <label class="form-check-label" for="Panier">Panier</label>
                    </div>
                  </div>
                  <div class="single-plan-check shadow-sm active-effect">
                    <div class="form-check mb-0">
                        <input v-model="radioRepas" value="Restaurant" class="form-check-input" type="radio" name="repas" id="Restaurant">
                        <label class="form-check-label" for="Restaurant">Restaurant</label>
                    </div>
                  </div>
                </div>

                <div class="text-left mt-4 mb-1"><label>Activité</label></div>
                <div class="form-check mb-0 text-left">
                  <input checked="checked" v-model="radioActivite" value="Chantier" class="form-check-input" type="radio" name="planChoose" id="Chantier">
                  <label class="form-check-label" for="Chantier">Chantier</label>
                </div>
                <div class="form-check mb-0 text-left">
                  <input v-model="radioActivite" value="Formation" class="form-check-input" type="radio" name="planChoose" id="Formation">
                  <label class="form-check-label" for="Formation">Formation</label>
                </div>
                <div class="form-check mb-0 text-left">
                  <input v-model="radioActivite" value="Autre" class="form-check-input" type="radio" name="planChoose" id="Autre">
                  <label class="form-check-label" for="Autre">Autre</label>
                </div>

                <div class="mb-4"></div>

                <div class="text-left mt-4 mb-1"><label>Temps passé (H)</label></div>
                <div class="row">
                  <div class="col-5">
                    <FormulateInput
                      type="number"
                      name="Temps passé (H)"
                      :show-labels="false" 
                      placeholder="Heure"
                      validation="number|between:0,10"
                      min="0"
                      max="10"
                      v-model="journee.heure"
                    />
                  </div>
                  <div class="col-2 mt-2">H</div>
                  <div class="col-5">
                    <FormulateInput
                      type="number"
                      name="Temps passé (H)"
                      :show-labels="false" 
                      placeholder="Minutes"
                      min="0"
                      max="55"
                      :step="5"
                      v-model="journee.minutes"
                    />
                  </div>
                </div>

                <div v-if="error" class="alert alert-warning w-100">
                  <i class="fas fa-exclamation-triangle"></i>
                  {{error}}
                </div>
                
                <FormulateInput
                  type="submit"
                  label="Valider"
                  id="buttonJournee"
                  input-class="btn btn-primary w-100 text-white mt-3"
                />
                
              </FormulateForm>

            </div>

          </div>
        </div>

  </div>
</template>

<script>
//import router from '../router'
import UserService from '../services/user.service'

var temp = Object.freeze({
  collaborateur: '',
  chantier: '',
  heure: '',
  minutes: '',
  date: '',
  zone: '',
  repas: '',
  activite: '',
});

export default {
  name: 'Form',
  components: {
  },
  props: {
    type: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      userData: [],
      formSelectChantier: [],
      error: null,
      journee: Object.assign({}, temp),
      editing: false,
      journeeNewId: null,
      tomorrow: null,
      radioRepas: 'Panier',
      radioZone: 'Z1',
      radioActivite: 'Chantier',
      today: null,
      isDone: false,
    }
  },
  async created() {
    this.userData = JSON.parse(localStorage.getItem('userData'));

    await UserService.getChantiersForm().then(
    response => {
      this.formSelectChantier = response.data['hydra:member'];
    },
    error => {
      this.content = (error.response && error.response.data) || error.message || error.toString();
    }
    );
    
    this.tomorrow = {
      disabledDates: {
        from: new Date(),
      },
    }

  },
  watch: {
    type(val) {
      if (val == 'add') {
        this.journee = Object.assign({}, temp);
      }
    }
  },
  mounted() {
    if ("id" in this.$route.params) {
      this.editing = true;
    } else {
      this.journee.date = new Date();
    }
  },
  methods: {
    async reload() {
       await UserService.getUserMe().then(
        response => {
          localStorage.setItem('userData', response.data);
        },
        error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
        }
      );
      this.$router.go();
    },
    async validationForm() {
      let duration = null;
        duration = this.journee.heure * 60;
        duration = duration + parseInt(this.journee.minutes);

        /* méthode convertion minutes => heures décimales*/
        let hours = null;
        let minutes = null;
        let hourDec = null;
        let dureeMin = null;
        hours = Math.floor(duration / 60);
        minutes = duration % 60;
        hourDec = minutes / 60;
        dureeMin = (hours + hourDec).toFixed(2);

      const journee = {
        chantier: this.journee.chantier.value != null ? "/api/chantiers/" + this.journee.chantier.value : null,
        date: this.journee.date,
        zone: this.radioZone,
        collaborateur: "/api/collaborateurs/" + this.userData.collaborateur.id,
        repas: this.radioRepas.toLowerCase(),
        activite: this.radioActivite.toLowerCase(),
        heures: dureeMin,
      };
      await UserService.postJournee(journee).then(
        res => {
          let journeeId = res.data['@id'];
          this.journeeNewId = journeeId.slice(13);
            UserService.getUserMe().then(
                response => {
                    localStorage.setItem('userData', response.data);
                },
                error => {
                    this.content = (error.response && error.response.data) || error.message || error.toString();
                }
            );
        },         
      );
      document.getElementById('buttonJournee').click();
      document.getElementById('closeModalJournee').click();
      this.reload();
    },  
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

